import React from "react";
import { Container, Row, Col, Tab, Tabs } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../Style/Promotion.css";

export default function Promotion() {
  const gameBacarat = "https://fs.cdnxn.com/landingLondon/Images/games/ca1.png";
  const dreamGaming = "https://fs.cdnxn.com/landingLondon/Images/games/ca2.png";
  const pgSlot = "https://fs.cdnxn.com/landingLondon/Images/games/ca12.png";
  const saGaming = "https://fs.cdnxn.com/landingLondon/Images/games/sl11.png";
  const hgGaming = "https://fs.cdnxn.com/landingLondon/Images/games/sl18.png";
  const lottoexit = "https://fs.cdnxn.com/landingLondon/Images/games/sp4.png";
  const muaypukyok = "https://fs.cdnxn.com/landingLondon/Images/games/sl3.png";
  const ppGaming = "https://fs.cdnxn.com/landingLondon/Images/games/lo2.png";
  const sexyGaming = "https://fs.cdnxn.com/landingLondon/Images/games/sl18.png";

  const btnBgDarkBlue =
    "https://fs.cdnxn.com/landingLondon/Images/btn-bg-darkblue.png";
  return (
    <>
      <div className="promotion-section">
        <img src={btnBgDarkBlue} className="bg--tab--blue" />
        <Container>
          <Row>
            <Tabs defaultActiveKey="tab-1">
              <Tab eventKey="tab-1" title="มาใหม่">
                <Row>
                  <Col md={4} xs={6} className="games mb-2">
                    <a href="https://game.lsmlondon789.asia/">
                      <img src={dreamGaming} className="w-100" />
                    </a>
                  </Col>
                  <Col md={4} xs={6} className="games mb-2">
                    <a href="https://game.lsmlondon789.asia/">
                      <img src={gameBacarat} className="w-100" />
                    </a>
                  </Col>
                  <Col md={4} xs={6} className="games mb-2">
                    <a href="https://game.lsmlondon789.asia/">
                      <img src={pgSlot} className="w-100" />
                    </a>
                  </Col>
                  <Col md={4} xs={6} className="games mb-2">
                    <a href="https://game.lsmlondon789.asia/">
                      <img src={saGaming} className="w-100" />
                    </a>
                  </Col>
                  <Col md={4} xs={6} className="games mb-2">
                    <a href="https://game.lsmlondon789.asia/">
                      <img src={hgGaming} className="w-100" />
                    </a>
                  </Col>
                  <Col md={4} xs={6} className="games mb-2">
                    <a href="https://game.lsmlondon789.asia/">
                      <img src={lottoexit} className="w-100" />
                    </a>
                  </Col>
                  <Col md={4} xs={6} className="games mb-2">
                    <a href="https://game.lsmlondon789.asia/">
                      <img src={muaypukyok} className="w-100" />
                    </a>
                  </Col>
                  <Col md={4} xs={6} className="games mb-2">
                    <a href="https://game.lsmlondon789.asia/">
                      <img src={ppGaming} className="w-100" />
                    </a>
                  </Col>
                  <Col md={4} xs={6} className="games mb-2">
                    <a href="https://game.lsmlondon789.asia/">
                      <img src={sexyGaming} className="w-100" />
                    </a>
                  </Col>
                </Row>
              </Tab>
              <Tab eventKey="tab-2" title="ยอดนิยม">
                <Row>
                  <Col md={4} xs={6} className="games mb-2">
                    <a href="https://game.lsmlondon789.asia/">
                      <img src={saGaming} className="w-100" />
                    </a>
                  </Col>
                  <Col md={4} xs={6} className="games mb-2">
                    <a href="https://game.lsmlondon789.asia/">
                      <img src={sexyGaming} className="w-100" />
                    </a>
                  </Col>
                  <Col md={4} xs={6} className="games mb-2">
                    <a href="https://game.lsmlondon789.asia/">
                      <img src={lottoexit} className="w-100" />
                    </a>
                  </Col>
                  <Col md={4} xs={6} className="games mb-2">
                    <a href="https://game.lsmlondon789.asia/">
                      <img src={muaypukyok} className="w-100" />
                    </a>
                  </Col>
                </Row>
              </Tab>
              <Tab eventKey="tab-3" title="ล่าสุด">
                <Row>
                  <Col md={4} xs={6} className="games mb-2">
                    <a href="https://game.lsmlondon789.asia/">
                      <img src={dreamGaming} className="w-100" />
                    </a>
                  </Col>
                  <Col md={4} xs={6} className="games mb-2">
                    <a href="https://game.lsmlondon789.asia/">
                      <img src={gameBacarat} className="w-100" />
                    </a>
                  </Col>
                  <Col md={4} xs={6} className="games mb-2">
                    <a href="https://game.lsmlondon789.asia/">
                      <img src={pgSlot} className="w-100" />
                    </a>
                  </Col>
                </Row>
              </Tab>
            </Tabs>
          </Row>
        </Container>
      </div>
    </>
  );
}
