import React from "react";
export default function Footer() {
  return (
    <div className="footer">
      <div className="footer--section">
        <div className="pc"></div>
        <div className="mobile">
          <div className="menu--section">
            <a
              href="https://game.lsmlondon789.asia/"
              className="go-to-homepage"
            ></a>
            <a href="/PromotionPage" className="go-to-promotion"></a>
            <div className="playgame--menu--bar">
              <img
                src={"https://fs.cdnxn.com/landingLondon/Images/play-games.png"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
