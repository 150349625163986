import React from "react";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import Promotion from "./Promotion";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/splide.min.css";
import { Container, Row, Col, Tab, Tabs } from "react-bootstrap";

export default function Casino() {
  const allGames =
    "https://fs.cdnxn.com/landingLondon/Images/menu-allAames.png";
  const allSlot = "https://fs.cdnxn.com/landingLondon/Images/menu-slot.png";
  const allSport = "https://fs.cdnxn.com/landingLondon/Images/menu-sport.png";
  const allLotto = "https://fs.cdnxn.com/landingLondon/Images/menu-lotto.png";
  const allCasino =
    "https://fs.cdnxn.com/landingLondon/Images/menu-casino-active.png";
  const btnBgDarkBlue =
    "https://fs.cdnxn.com/landingLondon/Images/btn-bg-darkblue.png";

  const gameBacarat =
    "https://fs.cdnxn.com/landingLondon/Images/game-bacarat.png";
  const dreamGaming =
    "https://fs.cdnxn.com/landingLondon/Images/game-dreamgaming.png";
  const pgSlot = "https://fs.cdnxn.com/landingLondon/Images/game-pg-slot.png";
  const saGaming =
    "https://fs.cdnxn.com/landingLondon/Images/game-sa-gaming.png";

  const promotion1 =
    "https://fs.cdnxn.com/landingLondon/Images/promotion/casino1.png";
  const promotion2 =
    "https://fs.cdnxn.com/landingLondon/Images/promotion/casino2.png";
  const promotion3 =
    "https://fs.cdnxn.com/landingLondon/Images/promotion/sport2.png";
  const promotion4 =
    "https://fs.cdnxn.com/landingLondon/Images/promotion/slot2.png";
  const promotion5 =
    "https://fs.cdnxn.com/landingLondon/Images/promotion/sport1.png";
  const promotion6 =
    "https://fs.cdnxn.com/landingLondon/Images/promotion/slot1.png";
  const promotion7 =
    "https://fs.cdnxn.com/landingLondon/Images/promotion/lotto1.png";

  const pro1 =
    "https://fs.cdnxn.com/landingLondon/Images/promotion/casino1.png";
  const pro2 =
    "https://fs.cdnxn.com/landingLondon/Images/promotion/casino2.png";
  const pro3 =
    "https://fs.cdnxn.com/landingLondon/Images/promotion/casino3.png";

  const games1 = "https://fs.cdnxn.com/landingLondon/Images/games/ca1.png";
  const games2 = "https://fs.cdnxn.com/landingLondon/Images/games/ca2.png";
  const games3 = "https://fs.cdnxn.com/landingLondon/Images/games/ca3.png";
  const games4 = "https://fs.cdnxn.com/landingLondon/Images/games/ca4.png";
  const games5 = "https://fs.cdnxn.com/landingLondon/Images/games/ca5.png";
  const games6 = "https://fs.cdnxn.com/landingLondon/Images/games/ca6.png";
  const games7 = "https://fs.cdnxn.com/landingLondon/Images/games/ca7.png";
  const games8 = "https://fs.cdnxn.com/landingLondon/Images/games/ca8.png";
  const games9 = "https://fs.cdnxn.com/landingLondon/Images/games/ca9.png";
  const games10 = "https://fs.cdnxn.com/landingLondon/Images/games/ca10.png";
  const games11 = "https://fs.cdnxn.com/landingLondon/Images/games/ca13.png";
  const games12 = "https://fs.cdnxn.com/landingLondon/Images/games/ca12.png";

  /* Mobile */
  const mbTitleTop =
    "https://fs.cdnxn.com/landingLondon/Images/mb-title-top.png";
  const mbTitleCategory =
    "https://fs.cdnxn.com/landingLondon/Images/mb-title-category.png";

  const headerGamesHitBg =
    "https://fs.cdnxn.com/landingLondon/Images/Intersect.png";
  const headerGamesHitDg = "https://fs.cdnxn.com/landingLondon/Images/dg.png";
  const headerGamesHitPretty =
    "https://fs.cdnxn.com/landingLondon/Images/pretty.png";
  const headerGamesHitSexy =
    "https://fs.cdnxn.com/landingLondon/Images/sexy.png";
  const headerBtnLine =
    "https://fs.cdnxn.com/landingLondon/Images/btn-line.png";

  const headerBtnLogin =
    "https://fs.cdnxn.com/landingLondon/Images/btn-header-login.png";
  const headerBtnRegister =
    "https://fs.cdnxn.com/landingLondon/Images/btn-header-register.png";

  const lightCar = "https://fs.cdnxn.com/landingLondon/Images/light-car.png";

  return (
    <>
      <Header />
      <div className="home">
        <div className="home--section">
          <div className="pc">
            <div className="left">
              <h3>เลือกเล่นเกมส์</h3>
              <div className="all--games--section">
                {/* <a href='/'><img src={allGames} /></a> */}
                <a href="/slot">
                  <img src={allSlot} />
                </a>
                <a href="/sport">
                  <img src={allSport} />
                </a>
                <a href="/casino">
                  <img
                    src={allCasino}
                    style={{ width: "110px", marginTop: "5px" }}
                  />
                </a>
                <a href="/lotto">
                  <img src={allLotto} />
                </a>
              </div>
              <div className="promotion-section">
                <img src={btnBgDarkBlue} className="bg--tab--blue" />
                <Container>
                  <Row>
                    <Tabs defaultActiveKey="tab-1">
                      <Tab eventKey="tab-1" title="มาใหม่">
                        <Row>
                          <Col md={4} xs={6} className="games mb-2">
                            <a href="https://game.lsmlondon789.asia/">
                              <img src={games1} className="w-100" />
                            </a>
                          </Col>
                          <Col md={4} xs={6} className="games mb-2">
                            <a href="https://game.lsmlondon789.asia/">
                              <img src={games2} className="w-100" />
                            </a>
                          </Col>
                          <Col md={4} xs={6} className="games mb-2">
                            <a href="https://game.lsmlondon789.asia/">
                              <img src={games3} className="w-100" />
                            </a>
                          </Col>
                          <Col md={4} xs={6} className="games mb-2">
                            <a href="https://game.lsmlondon789.asia/">
                              <img src={games5} className="w-100" />
                            </a>
                          </Col>
                          <Col md={4} xs={6} className="games mb-2">
                            <a href="https://game.lsmlondon789.asia/">
                              <img src={games6} className="w-100" />
                            </a>
                          </Col>
                          <Col md={4} xs={6} className="games mb-2">
                            <a href="https://game.lsmlondon789.asia/">
                              <img src={games7} className="w-100" />
                            </a>
                          </Col>
                          <Col md={4} xs={6} className="games mb-2">
                            <a href="https://game.lsmlondon789.asia/">
                              <img src={games8} className="w-100" />
                            </a>
                          </Col>
                          <Col md={4} xs={6} className="games mb-2">
                            <a href="https://game.lsmlondon789.asia/">
                              <img src={games9} className="w-100" />
                            </a>
                          </Col>
                          <Col md={4} xs={6} className="games mb-2">
                            <a href="https://game.lsmlondon789.asia/">
                              <img src={games10} className="w-100" />
                            </a>
                          </Col>
                          <Col md={4} xs={6} className="games mb-2">
                            <a href="https://game.lsmlondon789.asia/">
                              <img src={games11} className="w-100" />
                            </a>
                          </Col>
                          <Col md={4} xs={6} className="games mb-2">
                            <a href="https://game.lsmlondon789.asia/">
                              <img src={games12} className="w-100" />
                            </a>
                          </Col>
                        </Row>
                      </Tab>
                      <Tab eventKey="tab-2" title="ยอดนิยม">
                        <Row>
                          <Col md={4} xs={6} className="games mb-2">
                            <a href="https://game.lsmlondon789.asia/">
                              <img src={games1} className="w-100" />
                            </a>
                          </Col>
                          <Col md={4} xs={6} className="games mb-2">
                            <a href="https://game.lsmlondon789.asia/">
                              <img src={games12} className="w-100" />
                            </a>
                          </Col>
                          <Col md={4} xs={6} className="games mb-2">
                            <a href="https://game.lsmlondon789.asia/">
                              <img src={games10} className="w-100" />
                            </a>
                          </Col>
                          <Col md={4} xs={6} className="games mb-2">
                            <a href="https://game.lsmlondon789.asia/">
                              <img src={games2} className="w-100" />
                            </a>
                          </Col>
                        </Row>
                      </Tab>
                      <Tab eventKey="tab-3" title="ล่าสุด">
                        <Row>
                          <Col md={4} xs={6} className="games mb-2">
                            <a href="https://game.lsmlondon789.asia/">
                              <img src={games8} className="w-100" />
                            </a>
                          </Col>
                          <Col md={4} xs={6} className="games mb-2">
                            <a href="https://game.lsmlondon789.asia/">
                              <img src={games2} className="w-100" />
                            </a>
                          </Col>
                          <Col md={4} xs={6} className="games mb-2">
                            <a href="https://game.lsmlondon789.asia/">
                              <img src={games3} className="w-100" />
                            </a>
                          </Col>
                        </Row>
                      </Tab>
                    </Tabs>
                  </Row>
                </Container>
              </div>
            </div>{" "}
            {/* End Left */}
            <div className="right">
              <h3>โปรโมชั่นล่าสุด</h3>
              <div>
                <a href="https://game.lsmlondon789.asia/">
                  <img src={pro1} />
                </a>
              </div>
              <div>
                <a href="https://game.lsmlondon789.asia/">
                  <img src={pro2} />
                </a>
              </div>
              <div>
                <a href="https://game.lsmlondon789.asia/">
                  <img src={pro3} />
                </a>
              </div>
              {/* <div><a href='https://game.lsmlondon789.asia/'><img src={promotion4} /></a></div>
            <div><a href='https://game.lsmlondon789.asia/'><img src={promotion5} /></a></div>
            <div><a href='https://game.lsmlondon789.asia/'><img src={promotion6} /></a></div>
            <div><a href='https://game.lsmlondon789.asia/'><img src={promotion7} /></a></div> */}
            </div>
          </div>

          <div className="mobile">
            <div className="box-mb">
              <div className="mb--title-top mt-2">
                <marquee className="marquee--text">
                  เมื่ออยากหาอะไรสนุกเล่นแก้เบื่อ ให้นึกถึงเรา LSMLONDON789
                  เว็บพนันออนไลน์ที่มีทั้งเกมส์ กีฬา คาสิโน หวย
                  พร้อมด้วยโปรโมชั่นที่รองรับการเล่นของสมาชิกทุกชนิด
                  บริการรวดเร็วทันใจด้วยระบบ Auto แอดมินบริการ 24 ชม.
                  ติดปัญหาส่วนไหนเเจ้งแอดมินได้ตลอดเลยนะคะ
                </marquee>
                <img
                  src={btnBgDarkBlue}
                  className="bg--tab--blue"
                  alt="bg btn blue"
                />
              </div>

              <div className="mb--home--login">
                <div className="btn-register">
                  <a href="https://game.lsmlondon789.asia//register">
                    <img src={headerBtnRegister} alt="register" />
                  </a>
                </div>
                <div className="btn-login">
                  <a href="https://game.lsmlondon789.asia/">
                    <img src={headerBtnLogin} alt="login" />
                  </a>
                </div>
              </div>

              <div className="mb--promotion">
                <Splide
                  options={{
                    rewind: false,
                    gap: "10px",
                    type: "loop",
                    autoplay: true,
                    arrows: false,
                  }}
                >
                  <SplideSlide>
                    <a href="https://game.lsmlondon789.asia/">
                      <img src={promotion1} alt="Image 1" />
                    </a>
                  </SplideSlide>
                  <SplideSlide>
                    <a href="https://game.lsmlondon789.asia/">
                      <img src={promotion2} alt="Image 2" />
                    </a>
                  </SplideSlide>
                  <SplideSlide>
                    <a href="https://game.lsmlondon789.asia/">
                      <img src={promotion3} alt="Image 3" />
                    </a>
                  </SplideSlide>
                  <SplideSlide>
                    <a href="https://game.lsmlondon789.asia/">
                      <img src={promotion4} alt="Image 4" />
                    </a>
                  </SplideSlide>
                  <SplideSlide>
                    <a href="https://game.lsmlondon789.asia/">
                      <img src={promotion5} alt="Image 5" />
                    </a>
                  </SplideSlide>
                  <SplideSlide>
                    <a href="https://game.lsmlondon789.asia/">
                      <img src={promotion6} alt="Image 6" />
                    </a>
                  </SplideSlide>
                  <SplideSlide>
                    <a href="https://game.lsmlondon789.asia/">
                      <img src={promotion7} alt="Image 7" />
                    </a>
                  </SplideSlide>
                </Splide>
              </div>

              <div className="mb--title--category">
                <img src={mbTitleCategory} />
              </div>

              <div className="mb--all--games--section">
                {/* <a href='/'><img src={allGames} /></a> */}
                <a href="/slot">
                  <img src={allSlot} style={{ width: "100%" }} />
                </a>
                <a href="/sport">
                  <img src={allSport} style={{ width: "100%" }} />
                </a>
                <a href="/casino">
                  <img
                    src={allCasino}
                    style={{ width: "90%", marginTop: "8px" }}
                  />
                </a>
                <a href="/lotto">
                  <img src={allLotto} style={{ width: "100%" }} />
                </a>
              </div>

              <div className="mb--games">
                <div className="mb--games-tab-title">
                  <h5>ค่ายเกมส์ยอดนิยม</h5>
                  <a href="https://game.lsmlondon789.asia/">ดูทั้งหมด</a>
                </div>
                <div className="bg--games">
                  <div className="games">
                    <Splide
                      options={{
                        rewind: false,
                        gap: "10px",
                        type: "loop",
                        autoplay: true,
                        arrows: false,
                        perPage: 2,
                      }}
                    >
                      <SplideSlide>
                        <a href="https://game.lsmlondon789.asia/">
                          <img className="game--img" src={games1} alt="ab" />
                        </a>
                      </SplideSlide>
                      <SplideSlide>
                        <a href="https://game.lsmlondon789.asia/">
                          <img className="game--img" src={games2} alt="dg" />
                        </a>
                      </SplideSlide>
                      <SplideSlide>
                        <a href="https://game.lsmlondon789.asia/">
                          <img className="game--img" src={games3} alt="hg" />
                        </a>
                      </SplideSlide>
                      <SplideSlide>
                        <a href="https://game.lsmlondon789.asia/">
                          <img
                            className="game--img"
                            src={games4}
                            alt="lotto exit"
                          />
                        </a>
                      </SplideSlide>
                      <SplideSlide>
                        <a href="https://game.lsmlondon789.asia/">
                          <img
                            className="game--img"
                            src={games5}
                            alt="muaypukyok"
                          />
                        </a>
                      </SplideSlide>
                      <SplideSlide>
                        <a href="https://game.lsmlondon789.asia/">
                          <img className="game--img" src={games6} alt="pg" />
                        </a>
                      </SplideSlide>
                      <SplideSlide>
                        <a href="https://game.lsmlondon789.asia/">
                          <img className="game--img" src={games7} alt="pp" />
                        </a>
                      </SplideSlide>
                      <SplideSlide>
                        <a href="https://game.lsmlondon789.asia/">
                          <img className="game--img" src={games8} alt="ps" />
                        </a>
                      </SplideSlide>
                      <SplideSlide>
                        <a href="https://game.lsmlondon789.asia/">
                          <img className="game--img" src={games9} alt="sa" />
                        </a>
                      </SplideSlide>
                      <SplideSlide>
                        <a href="https://game.lsmlondon789.asia/">
                          <img className="game--img" src={games10} alt="sexy" />
                        </a>
                      </SplideSlide>
                    </Splide>
                  </div>
                  <img src={headerGamesHitBg} className="img--gameshit--bg" />
                </div>
              </div>

              <div className="mb--contact">
                <a href="https://line.me/R/ti/p/@559tjxps" target="_blank">
                  <div className="mb--contact--line">
                    <img src={headerBtnLine} />
                    <p>เป็นเพื่อนกับเรา Add Friends</p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="light-car">
        <img src={lightCar} />
      </div>
      <Footer />
    </>
  );
}
