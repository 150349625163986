import React, { useState } from "react";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import Modal from "react-bootstrap/Modal";
import { Container, Row, Col, Tab, Tabs } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../Style/PromotionPage.css";
import "../Style/OpenMenuHamburger.css";

export default function PromotionPage() {
  const btnBgDarkBlue =
    "https://fs.cdnxn.com/landingLondon/Images/btn-bg-darkblue.png";
  const playGamesMenuBarPromotion =
    "https://fs.cdnxn.com/landingLondon/Images/play-game-promotion.png";

  const casino1 =
    "https://fs.cdnxn.com/landingLondon/Images/promotion/casino1.png";
  const casino2 =
    "https://fs.cdnxn.com/landingLondon/Images/promotion/casino2.png";
  const casino3 =
    "https://fs.cdnxn.com/landingLondon/Images/promotion/casino3.png";
  const casino4 =
    "https://fs.cdnxn.com/landingLondon/Images/promotion/casino4.png";

  const lotto1 =
    "https://fs.cdnxn.com/landingLondon/Images/promotion/lotto1.png";

  const slot1 = "https://fs.cdnxn.com/landingLondon/Images/promotion/slot1.png";
  const slot2 = "https://fs.cdnxn.com/landingLondon/Images/promotion/slot2.png";
  const slot3 = "https://fs.cdnxn.com/landingLondon/Images/promotion/slot3.png";
  const slot4 = "https://fs.cdnxn.com/landingLondon/Images/promotion/slot4.png";
  const slot5 = "https://fs.cdnxn.com/landingLondon/Images/promotion/slot5.png";
  const slot6 = "https://fs.cdnxn.com/landingLondon/Images/promotion/slot6.png";
  const slot7 = "https://fs.cdnxn.com/landingLondon/Images/promotion/slot7.png";
  const slot8 = "https://fs.cdnxn.com/landingLondon/Images/promotion/slot8.png";
  const slot9 = "https://fs.cdnxn.com/landingLondon/Images/promotion/slot9.png";

  const sport1 =
    "https://fs.cdnxn.com/landingLondon/Images/promotion/sport1.png";
  const sport2 =
    "https://fs.cdnxn.com/landingLondon/Images/promotion/sport2.png";
  const sport3 =
    "https://fs.cdnxn.com/landingLondon/Images/promotion/sport3.png";
  const sport4 =
    "https://fs.cdnxn.com/landingLondon/Images/promotion/sport4.png";
  const sport5 =
    "https://fs.cdnxn.com/landingLondon/Images/promotion/sport5.png";
  const sport6 =
    "https://fs.cdnxn.com/landingLondon/Images/promotion/sport6.png";
  const sport7 =
    "https://fs.cdnxn.com/landingLondon/Images/promotion/sport7.png";
  const sport8 =
    "https://fs.cdnxn.com/landingLondon/Images/promotion/sport8.png";
  const sport9 =
    "https://fs.cdnxn.com/landingLondon/Images/promotion/sport9.png";
  const sport10 =
    "https://fs.cdnxn.com/landingLondon/Images/promotion/sport10.png";

  const menuPlayGame =
    "https://fs.cdnxn.com/landingLondon/Images/menu-play-game.png";
  const menuCashBack =
    "https://fs.cdnxn.com/landingLondon/Images/menu-cash-back.png";
  const menuHistory =
    "https://fs.cdnxn.com/landingLondon/Images/menu-history.png";
  const menuPromotion =
    "https://fs.cdnxn.com/landingLondon/Images/menu-promotion.png";
  const menuAffiliate =
    "https://fs.cdnxn.com/landingLondon/Images/menu-affiliate.png";
  const menuContact =
    "https://fs.cdnxn.com/landingLondon/Images/menu-contact.png";

  const headerBg = "https://fs.cdnxn.com/landingLondon/Images/header-bg.png";
  const headerStamp =
    "https://fs.cdnxn.com/landingLondon/Images/header-stamp.png";
  const headerLogoBlack =
    "https://fs.cdnxn.com/landingLondon/Images/logo-black.png";
  // const headerLogoBlack = "https://fs.cdnxn.com/landingLondon/Images/london.gif"
  const headerBtnLogin =
    "https://fs.cdnxn.com/landingLondon/Images/btn-header-login.png";
  const headerBtnRegister =
    "https://fs.cdnxn.com/landingLondon/Images/btn-header-register.png";
  const headerBtnLine =
    "https://fs.cdnxn.com/landingLondon/Images/btn-line.png";
  const headerBtnNofi = "https://fs.cdnxn.com/landingLondon/Images/nofi.png";
  const headerBtnBurgerBar =
    "https://fs.cdnxn.com/landingLondon/Images/burgerbar.png";

  const headerBgMb =
    "https://fs.cdnxn.com/landingLondon/Images/header-bg-mobile.png";

  const nofiCateAll =
    "https://fs.cdnxn.com/landingLondon/Images/nofi-category-all.png";
  const nofiDeposit =
    "https://fs.cdnxn.com/landingLondon/Images/nofi-deposit.png";
  const nofiWithdraw =
    "https://fs.cdnxn.com/landingLondon/Images/nofi-withdraw.png";
  const nofiBonus =
    "https://fs.cdnxn.com/landingLondon/Images/nofi-cash-back.png";

  const headerBgBtnLine =
    "https://fs.cdnxn.com/landingLondon/Images/btn-bg-white.png";
  const [show, setShow] = useState(false);
  const [showNotification, setShowNotification] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleNotificationClose = () => setShowNotification(false);
  const handleNotificationShow = () => setShowNotification(true);
  return (
    <>
      <div className="header">
        <div className="section--img">
          <div className="pc">
            <div className="section--item">
              <div className="section--logo">
                <a href="/">
                  <img src={headerLogoBlack} className="img--logo" />
                </a>

                <div className="section--username">
                  {/* <div className='btn-login'>
                            <a href='https://game.lsmlondon789.asia/'><img src={headerBtnLogin} /></a>
                        </div> */}

                  <div className="btn-register">
                    <a href="https://game.lsmlondon789.asia/">
                      <img src={headerBtnRegister} />
                    </a>
                  </div>
                  <div className="btn-login">
                    <a href="https://game.lsmlondon789.asia/">
                      <img src={headerBtnLogin} />
                    </a>
                  </div>
                </div>
              </div>

              {/* <a href='https://game.lsmlondon789.asia/'> */}
              {/* <img src={headerPlaygame} className='header-play-games' /> */}
              <div className="header-play-games-section-top">
                <a href="/" className="go-to-homepage"></a>
                <a href="/PromotionPage" className="go-to-promotion"></a>
                <img
                  src={playGamesMenuBarPromotion}
                  className="header-play-games"
                />
              </div>
              {/* </a> */}

              {/* <div className='header--right'>
                <a href='https://game.lsmlondon789.asia/'>
                    <div className='line'>
                        <img src={headerBtnLine} className="header--right-line--icon" />
                        <p>เป็นเพื่อนกับเรา</p>
                    </div>
                </a>
                <a href='#'><img src={headerBtnNofi} className='btn--notify' onClick={handleNotificationShow} /></a>
                <a href='#'><img src={headerBtnBurgerBar} className='btn--burgerbar' onClick={handleShow}/></a>
              </div> */}

              <div className="header--right">
                <div className="line">
                  <a href="https://line.me/R/ti/p/@559tjxps" target="_blank">
                    <img src={headerBtnLine} className="imgHeaderLines" />{" "}
                    <p>เป็นเพื่อนกับเรา</p>
                    <img src={headerBgBtnLine} className="imgHeaderBgBtnLine" />
                  </a>
                </div>
                <a href="#">
                  <img
                    src={headerBtnNofi}
                    className="btn--notify"
                    onClick={handleNotificationShow}
                  />
                </a>
                <a href="#">
                  <img
                    src={headerBtnBurgerBar}
                    className="btn--burgerbar"
                    onClick={handleShow}
                  />
                </a>
              </div>
            </div>
            <img src={headerBg} className="header-bg" alt="header background" />
            <img src={headerStamp} className="header-stamp" alt="img stamp" />
          </div>

          <div className="mobile">
            <img
              src={headerBgMb}
              className="mb--header-bg"
              alt="london background mobile"
            />

            <div className="mb--section">
              <div className="mb--login">
                <div className="btn-login">
                  <a href="https://game.lsmlondon789.asia/">
                    <img
                      src={headerBtnLogin}
                      className="img-btn-login"
                      alt="img-btn-login"
                    />
                  </a>
                </div>
              </div>

              <div className="mb--logo">
                <a href="/">
                  <img
                    src={headerLogoBlack}
                    className="img--logo mobile"
                    alt="img-logo"
                  />
                </a>
              </div>

              <div className="mb--right">
                <a href="#">
                  <img
                    src={headerBtnNofi}
                    className="btn--notify"
                    onClick={handleNotificationShow}
                    alt="img-btn-notify"
                  />
                </a>
                <a href="#">
                  <img
                    src={headerBtnBurgerBar}
                    className="btn--burgerbar"
                    onClick={handleShow}
                    alt="img-burger-bar"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pc">
        <div className="promotion--page-section">
          <Container>
            <h3>โปรโมชั่นล่าสุด</h3>
            <Row>
              <div className="div--bg--tab--blue">
                {/*<img src={btnBgDarkBlue} alt="backgroud" className="bg--tab--blue" />*/}
              </div>
              <Tabs defaultActiveKey="tab-1">
                <Tab eventKey="tab-1" title="ทั้งหมด">
                  <Row>
                    <Col md={4} xs={6} className="games mb-2">
                      <a href="https://game.lsmlondon789.asia/">
                        <img src={casino1} alt="promotion" className="w-100" />
                      </a>
                    </Col>
                    <Col md={4} xs={6} className="games mb-2">
                      <a href="https://game.lsmlondon789.asia/">
                        <img src={casino2} alt="promotion" className="w-100" />
                      </a>
                    </Col>
                    <Col md={4} xs={6} className="games mb-2">
                      <a href="https://game.lsmlondon789.asia/">
                        <img src={casino3} alt="promotion" className="w-100" />
                      </a>
                    </Col>
                    <Col md={4} xs={6} className="games mb-2">
                      <a href="https://game.lsmlondon789.asia/">
                        <img src={casino4} alt="promotion" className="w-100" />
                      </a>
                    </Col>

                    <Col md={4} xs={6} className="games mb-2">
                      <a href="https://game.lsmlondon789.asia/">
                        <img src={lotto1} alt="promotion" className="w-100" />
                      </a>
                    </Col>

                    <Col md={4} xs={6} className="games mb-2">
                      <a href="https://game.lsmlondon789.asia/">
                        <img src={slot1} alt="promotion" className="w-100" />
                      </a>
                    </Col>
                    <Col md={4} xs={6} className="games mb-2">
                      <a href="https://game.lsmlondon789.asia/">
                        <img src={slot2} alt="promotion" className="w-100" />
                      </a>
                    </Col>
                    <Col md={4} xs={6} className="games mb-2">
                      <a href="https://game.lsmlondon789.asia/">
                        <img src={slot3} alt="promotion" className="w-100" />
                      </a>
                    </Col>
                    <Col md={4} xs={6} className="games mb-2">
                      <a href="https://game.lsmlondon789.asia/">
                        <img src={slot4} alt="promotion" className="w-100" />
                      </a>
                    </Col>
                    <Col md={4} xs={6} className="games mb-2">
                      <a href="https://game.lsmlondon789.asia/">
                        <img src={slot5} alt="promotion" className="w-100" />
                      </a>
                    </Col>
                    <Col md={4} xs={6} className="games mb-2">
                      <a href="https://game.lsmlondon789.asia/">
                        <img src={slot6} alt="promotion" className="w-100" />
                      </a>
                    </Col>
                    <Col md={4} xs={6} className="games mb-2">
                      <a href="https://game.lsmlondon789.asia/">
                        <img src={slot7} alt="promotion" className="w-100" />
                      </a>
                    </Col>
                    <Col md={4} xs={6} className="games mb-2">
                      <a href="https://game.lsmlondon789.asia/">
                        <img src={slot8} alt="promotion" className="w-100" />
                      </a>
                    </Col>
                    <Col md={4} xs={6} className="games mb-2">
                      <a href="https://game.lsmlondon789.asia/">
                        <img src={slot9} alt="promotion" className="w-100" />
                      </a>
                    </Col>

                    <Col md={4} xs={6} className="games mb-2">
                      <a href="https://game.lsmlondon789.asia/">
                        <img src={sport1} alt="promotion" className="w-100" />
                      </a>
                    </Col>
                    <Col md={4} xs={6} className="games mb-2">
                      <a href="https://game.lsmlondon789.asia/">
                        <img src={sport2} alt="promotion" className="w-100" />
                      </a>
                    </Col>
                    <Col md={4} xs={6} className="games mb-2">
                      <a href="https://game.lsmlondon789.asia/">
                        <img src={sport3} alt="promotion" className="w-100" />
                      </a>
                    </Col>
                    <Col md={4} xs={6} className="games mb-2">
                      <a href="https://game.lsmlondon789.asia/">
                        <img src={sport4} alt="promotion" className="w-100" />
                      </a>
                    </Col>
                    <Col md={4} xs={6} className="games mb-2">
                      <a href="https://game.lsmlondon789.asia/">
                        <img src={sport5} alt="promotion" className="w-100" />
                      </a>
                    </Col>
                    <Col md={4} xs={6} className="games mb-2">
                      <a href="https://game.lsmlondon789.asia/">
                        <img src={sport6} alt="promotion" className="w-100" />
                      </a>
                    </Col>
                    <Col md={4} xs={6} className="games mb-2">
                      <a href="https://game.lsmlondon789.asia/">
                        <img src={sport7} alt="promotion" className="w-100" />
                      </a>
                    </Col>
                    <Col md={4} xs={6} className="games mb-2">
                      <a href="https://game.lsmlondon789.asia/">
                        <img src={sport8} alt="promotion" className="w-100" />
                      </a>
                    </Col>
                    <Col md={4} xs={6} className="games mb-2">
                      <a href="https://game.lsmlondon789.asia/">
                        <img src={sport9} alt="promotion" className="w-100" />
                      </a>
                    </Col>
                    <Col md={4} xs={6} className="games mb-2">
                      <a href="https://game.lsmlondon789.asia/">
                        <img src={sport10} alt="promotion" className="w-100" />
                      </a>
                    </Col>
                  </Row>
                </Tab>
                <Tab eventKey="tab-2" title="กีฬา">
                  <Row>
                    <Col md={4} xs={6} className="games mb-2">
                      <a href="https://game.lsmlondon789.asia/">
                        <img src={sport1} alt="promotion" className="w-100" />
                      </a>
                    </Col>
                    <Col md={4} xs={6} className="games mb-2">
                      <a href="https://game.lsmlondon789.asia/">
                        <img src={sport2} alt="promotion" className="w-100" />
                      </a>
                    </Col>
                    <Col md={4} xs={6} className="games mb-2">
                      <a href="https://game.lsmlondon789.asia/">
                        <img src={sport3} alt="promotion" className="w-100" />
                      </a>
                    </Col>
                    <Col md={4} xs={6} className="games mb-2">
                      <a href="https://game.lsmlondon789.asia/">
                        <img src={sport4} alt="promotion" className="w-100" />
                      </a>
                    </Col>
                    <Col md={4} xs={6} className="games mb-2">
                      <a href="https://game.lsmlondon789.asia/">
                        <img src={sport5} alt="promotion" className="w-100" />
                      </a>
                    </Col>
                    <Col md={4} xs={6} className="games mb-2">
                      <a href="https://game.lsmlondon789.asia/">
                        <img src={sport6} alt="promotion" className="w-100" />
                      </a>
                    </Col>
                    <Col md={4} xs={6} className="games mb-2">
                      <a href="https://game.lsmlondon789.asia/">
                        <img src={sport7} alt="promotion" className="w-100" />
                      </a>
                    </Col>
                    <Col md={4} xs={6} className="games mb-2">
                      <a href="https://game.lsmlondon789.asia/">
                        <img src={sport8} alt="promotion" className="w-100" />
                      </a>
                    </Col>
                    <Col md={4} xs={6} className="games mb-2">
                      <a href="https://game.lsmlondon789.asia/">
                        <img src={sport9} alt="promotion" className="w-100" />
                      </a>
                    </Col>
                    <Col md={4} xs={6} className="games mb-2">
                      <a href="https://game.lsmlondon789.asia/">
                        <img src={sport10} alt="promotion" className="w-100" />
                      </a>
                    </Col>
                  </Row>
                </Tab>
                <Tab eventKey="tab-3" title="คาสิโน">
                  <Row>
                    <Col md={4} xs={6} className="games mb-2">
                      <a href="https://game.lsmlondon789.asia/">
                        <img src={casino1} alt="promotion" className="w-100" />
                      </a>
                    </Col>
                    <Col md={4} xs={6} className="games mb-2">
                      <a href="https://game.lsmlondon789.asia/">
                        <img src={casino2} alt="promotion" className="w-100" />
                      </a>
                    </Col>
                    <Col md={4} xs={6} className="games mb-2">
                      <a href="https://game.lsmlondon789.asia/">
                        <img src={casino3} alt="promotion" className="w-100" />
                      </a>
                    </Col>
                    <Col md={4} xs={6} className="games mb-2">
                      <a href="https://game.lsmlondon789.asia/">
                        <img src={casino4} alt="promotion" className="w-100" />
                      </a>
                    </Col>
                  </Row>
                </Tab>
                <Tab eventKey="tab-4" title="สล็อต">
                  <Row>
                    <Col md={4} xs={6} className="games mb-2">
                      <a href="https://game.lsmlondon789.asia/">
                        <img src={slot1} alt="promotion" className="w-100" />
                      </a>
                    </Col>
                    <Col md={4} xs={6} className="games mb-2">
                      <a href="https://game.lsmlondon789.asia/">
                        <img src={slot2} alt="promotion" className="w-100" />
                      </a>
                    </Col>
                    <Col md={4} xs={6} className="games mb-2">
                      <a href="https://game.lsmlondon789.asia/">
                        <img src={slot3} alt="promotion" className="w-100" />
                      </a>
                    </Col>
                    <Col md={4} xs={6} className="games mb-2">
                      <a href="https://game.lsmlondon789.asia/">
                        <img src={slot4} alt="promotion" className="w-100" />
                      </a>
                    </Col>
                    <Col md={4} xs={6} className="games mb-2">
                      <a href="https://game.lsmlondon789.asia/">
                        <img src={slot5} alt="promotion" className="w-100" />
                      </a>
                    </Col>
                    <Col md={4} xs={6} className="games mb-2">
                      <a href="https://game.lsmlondon789.asia/">
                        <img src={slot6} alt="promotion" className="w-100" />
                      </a>
                    </Col>
                    <Col md={4} xs={6} className="games mb-2">
                      <a href="https://game.lsmlondon789.asia/">
                        <img src={slot7} alt="promotion" className="w-100" />
                      </a>
                    </Col>
                    <Col md={4} xs={6} className="games mb-2">
                      <a href="https://game.lsmlondon789.asia/">
                        <img src={slot8} alt="promotion" className="w-100" />
                      </a>
                    </Col>
                    <Col md={4} xs={6} className="games mb-2">
                      <a href="https://game.lsmlondon789.asia/">
                        <img src={slot9} alt="promotion" className="w-100" />
                      </a>
                    </Col>
                  </Row>
                </Tab>
                <Tab eventKey="tab-5" title="หวย">
                  <Row>
                    <Col md={4} xs={6} className="games mb-2">
                      <a href="https://game.lsmlondon789.asia/">
                        <img src={lotto1} alt="promotion" className="w-100" />
                      </a>
                    </Col>
                  </Row>
                </Tab>
              </Tabs>
            </Row>
          </Container>
        </div>
      </div>
      <div className="mobile">
        <div className="promotion--page-section">
          <div className="mb--title-top mt-2">
            <marquee className="marquee--text">
              เมื่ออยากหาอะไรสนุกเล่นแก้เบื่อ ให้นึกถึงเรา LSMLONDON789
              เว็บพนันออนไลน์ที่มีทั้งเกมส์ กีฬา คาสิโน หวย
              พร้อมด้วยโปรโมชั่นที่รองรับการเล่นของสมาชิกทุกชนิด
              บริการรวดเร็วทันใจด้วยระบบ Auto แอดมินบริการ 24 ชม.
              ติดปัญหาส่วนไหนเเจ้งแอดมินได้ตลอดเลยนะคะ
            </marquee>
            <img src={btnBgDarkBlue} className="bg--tab--blue" />
          </div>
          <Container>
            <div className="box-pro">
              <Row>
                <div className="div--bg--tab--blue">
                  {/*<img src={btnBgDarkBlue} className="bg--tab--blue" />*/}
                </div>
                <Tabs defaultActiveKey="tab-1">
                  <Tab eventKey="tab-1" title="ทั้งหมด">
                    <Row>
                      <Col md={4} xs={6} className="games mb-2">
                        <a href="https://game.lsmlondon789.asia/">
                          <img
                            src={casino1}
                            alt="promotion"
                            className="w-100"
                          />
                        </a>
                      </Col>
                      <Col md={4} xs={6} className="games mb-2">
                        <a href="https://game.lsmlondon789.asia/">
                          <img
                            src={casino2}
                            alt="promotion"
                            className="w-100"
                          />
                        </a>
                      </Col>
                      <Col md={4} xs={6} className="games mb-2">
                        <a href="https://game.lsmlondon789.asia/">
                          <img
                            src={casino3}
                            alt="promotion"
                            className="w-100"
                          />
                        </a>
                      </Col>
                      <Col md={4} xs={6} className="games mb-2">
                        <a href="https://game.lsmlondon789.asia/">
                          <img
                            src={casino4}
                            alt="promotion"
                            className="w-100"
                          />
                        </a>
                      </Col>

                      <Col md={4} xs={6} className="games mb-2">
                        <a href="https://game.lsmlondon789.asia/">
                          <img src={lotto1} alt="promotion" className="w-100" />
                        </a>
                      </Col>

                      <Col md={4} xs={6} className="games mb-2">
                        <a href="https://game.lsmlondon789.asia/">
                          <img src={slot1} alt="promotion" className="w-100" />
                        </a>
                      </Col>
                      <Col md={4} xs={6} className="games mb-2">
                        <a href="https://game.lsmlondon789.asia/">
                          <img src={slot2} alt="promotion" className="w-100" />
                        </a>
                      </Col>
                      <Col md={4} xs={6} className="games mb-2">
                        <a href="https://game.lsmlondon789.asia/">
                          <img src={slot3} alt="promotion" className="w-100" />
                        </a>
                      </Col>
                      <Col md={4} xs={6} className="games mb-2">
                        <a href="https://game.lsmlondon789.asia/">
                          <img src={slot4} alt="promotion" className="w-100" />
                        </a>
                      </Col>
                      <Col md={4} xs={6} className="games mb-2">
                        <a href="https://game.lsmlondon789.asia/">
                          <img src={slot5} alt="promotion" className="w-100" />
                        </a>
                      </Col>
                      <Col md={4} xs={6} className="games mb-2">
                        <a href="https://game.lsmlondon789.asia/">
                          <img src={slot6} alt="promotion" className="w-100" />
                        </a>
                      </Col>
                      <Col md={4} xs={6} className="games mb-2">
                        <a href="https://game.lsmlondon789.asia/">
                          <img src={slot7} alt="promotion" className="w-100" />
                        </a>
                      </Col>
                      <Col md={4} xs={6} className="games mb-2">
                        <a href="https://game.lsmlondon789.asia/">
                          <img src={slot8} alt="promotion" className="w-100" />
                        </a>
                      </Col>
                      <Col md={4} xs={6} className="games mb-2">
                        <a href="https://game.lsmlondon789.asia/">
                          <img src={slot9} alt="promotion" className="w-100" />
                        </a>
                      </Col>

                      <Col md={4} xs={6} className="games mb-2">
                        <a href="https://game.lsmlondon789.asia/">
                          <img src={sport1} alt="promotion" className="w-100" />
                        </a>
                      </Col>
                      <Col md={4} xs={6} className="games mb-2">
                        <a href="https://game.lsmlondon789.asia/">
                          <img src={sport2} alt="promotion" className="w-100" />
                        </a>
                      </Col>
                      <Col md={4} xs={6} className="games mb-2">
                        <a href="https://game.lsmlondon789.asia/">
                          <img src={sport3} alt="promotion" className="w-100" />
                        </a>
                      </Col>
                      <Col md={4} xs={6} className="games mb-2">
                        <a href="https://game.lsmlondon789.asia/">
                          <img src={sport4} alt="promotion" className="w-100" />
                        </a>
                      </Col>
                      <Col md={4} xs={6} className="games mb-2">
                        <a href="https://game.lsmlondon789.asia/">
                          <img src={sport5} alt="promotion" className="w-100" />
                        </a>
                      </Col>
                      <Col md={4} xs={6} className="games mb-2">
                        <a href="https://game.lsmlondon789.asia/">
                          <img src={sport6} alt="promotion" className="w-100" />
                        </a>
                      </Col>
                      <Col md={4} xs={6} className="games mb-2">
                        <a href="https://game.lsmlondon789.asia/">
                          <img src={sport7} alt="promotion" className="w-100" />
                        </a>
                      </Col>
                      <Col md={4} xs={6} className="games mb-2">
                        <a href="https://game.lsmlondon789.asia/">
                          <img src={sport8} alt="promotion" className="w-100" />
                        </a>
                      </Col>
                      <Col md={4} xs={6} className="games mb-2">
                        <a href="https://game.lsmlondon789.asia/">
                          <img src={sport9} alt="promotion" className="w-100" />
                        </a>
                      </Col>
                      <Col md={4} xs={6} className="games mb-2">
                        <a href="https://game.lsmlondon789.asia/">
                          <img
                            src={sport10}
                            alt="promotion"
                            className="w-100"
                          />
                        </a>
                      </Col>
                    </Row>
                  </Tab>
                  <Tab eventKey="tab-2" title="กีฬา">
                    <Row>
                      <Col md={4} xs={6} className="games mb-2">
                        <a href="https://game.lsmlondon789.asia/">
                          <img src={sport1} alt="promotion" className="w-100" />
                        </a>
                      </Col>
                      <Col md={4} xs={6} className="games mb-2">
                        <a href="https://game.lsmlondon789.asia/">
                          <img src={sport2} alt="promotion" className="w-100" />
                        </a>
                      </Col>
                      <Col md={4} xs={6} className="games mb-2">
                        <a href="https://game.lsmlondon789.asia/">
                          <img src={sport3} alt="promotion" className="w-100" />
                        </a>
                      </Col>
                      <Col md={4} xs={6} className="games mb-2">
                        <a href="https://game.lsmlondon789.asia/">
                          <img src={sport4} alt="promotion" className="w-100" />
                        </a>
                      </Col>
                      <Col md={4} xs={6} className="games mb-2">
                        <a href="https://game.lsmlondon789.asia/">
                          <img src={sport5} alt="promotion" className="w-100" />
                        </a>
                      </Col>
                      <Col md={4} xs={6} className="games mb-2">
                        <a href="https://game.lsmlondon789.asia/">
                          <img src={sport6} alt="promotion" className="w-100" />
                        </a>
                      </Col>
                      <Col md={4} xs={6} className="games mb-2">
                        <a href="https://game.lsmlondon789.asia/">
                          <img src={sport7} alt="promotion" className="w-100" />
                        </a>
                      </Col>
                      <Col md={4} xs={6} className="games mb-2">
                        <a href="https://game.lsmlondon789.asia/">
                          <img src={sport8} alt="promotion" className="w-100" />
                        </a>
                      </Col>
                      <Col md={4} xs={6} className="games mb-2">
                        <a href="https://game.lsmlondon789.asia/">
                          <img src={sport9} alt="promotion" className="w-100" />
                        </a>
                      </Col>
                      <Col md={4} xs={6} className="games mb-2">
                        <a href="https://game.lsmlondon789.asia/">
                          <img
                            src={sport10}
                            alt="promotion"
                            className="w-100"
                          />
                        </a>
                      </Col>
                    </Row>
                  </Tab>
                  <Tab eventKey="tab-3" title="คาสิโน">
                    <Row>
                      <Col md={4} xs={6} className="games mb-2">
                        <a href="https://game.lsmlondon789.asia/">
                          <img
                            src={casino1}
                            alt="promotion"
                            className="w-100"
                          />
                        </a>
                      </Col>
                      <Col md={4} xs={6} className="games mb-2">
                        <a href="https://game.lsmlondon789.asia/">
                          <img
                            src={casino2}
                            alt="promotion"
                            className="w-100"
                          />
                        </a>
                      </Col>
                      <Col md={4} xs={6} className="games mb-2">
                        <a href="https://game.lsmlondon789.asia/">
                          <img
                            src={casino3}
                            alt="promotion"
                            className="w-100"
                          />
                        </a>
                      </Col>
                      <Col md={4} xs={6} className="games mb-2">
                        <a href="https://game.lsmlondon789.asia/">
                          <img
                            src={casino4}
                            alt="promotion"
                            className="w-100"
                          />
                        </a>
                      </Col>
                    </Row>
                  </Tab>
                  <Tab eventKey="tab-4" title="สล็อต">
                    <Row>
                      <Col md={4} xs={6} className="games mb-2">
                        <a href="https://game.lsmlondon789.asia/">
                          <img src={slot1} alt="promotion" className="w-100" />
                        </a>
                      </Col>
                      <Col md={4} xs={6} className="games mb-2">
                        <a href="https://game.lsmlondon789.asia/">
                          <img src={slot2} alt="promotion" className="w-100" />
                        </a>
                      </Col>
                      <Col md={4} xs={6} className="games mb-2">
                        <a href="https://game.lsmlondon789.asia/">
                          <img src={slot3} alt="promotion" className="w-100" />
                        </a>
                      </Col>
                      <Col md={4} xs={6} className="games mb-2">
                        <a href="https://game.lsmlondon789.asia/">
                          <img src={slot4} alt="promotion" className="w-100" />
                        </a>
                      </Col>
                      <Col md={4} xs={6} className="games mb-2">
                        <a href="https://game.lsmlondon789.asia/">
                          <img src={slot5} alt="promotion" className="w-100" />
                        </a>
                      </Col>
                      <Col md={4} xs={6} className="games mb-2">
                        <a href="https://game.lsmlondon789.asia/">
                          <img src={slot6} alt="promotion" className="w-100" />
                        </a>
                      </Col>
                      <Col md={4} xs={6} className="games mb-2">
                        <a href="https://game.lsmlondon789.asia/">
                          <img src={slot7} alt="promotion" className="w-100" />
                        </a>
                      </Col>
                      <Col md={4} xs={6} className="games mb-2">
                        <a href="https://game.lsmlondon789.asia/">
                          <img src={slot8} alt="promotion" className="w-100" />
                        </a>
                      </Col>
                      <Col md={4} xs={6} className="games mb-2">
                        <a href="https://game.lsmlondon789.asia/">
                          <img src={slot9} alt="promotion" className="w-100" />
                        </a>
                      </Col>
                    </Row>
                  </Tab>
                  <Tab eventKey="tab-5" title="หวย">
                    <Row>
                      <Col md={4} xs={6} className="games mb-2">
                        <a href="https://game.lsmlondon789.asia/">
                          <img src={lotto1} alt="promotion" className="w-100" />
                        </a>
                      </Col>
                    </Row>
                  </Tab>
                </Tabs>
              </Row>
            </div>
          </Container>
        </div>
      </div>

      <div className="footer">
        <div className="footer--section">
          <div className="pc"></div>
          <div className="mobile">
            <div className="menu--section">
              <a href="/" className="go-to-homepage"></a>
              <a href="/PromotionPage" className="go-to-promotion"></a>
              <div className="playgame--menu--bar">
                <img src={playGamesMenuBarPromotion} />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <OpenMenuHamburger/> */}
      <Modal
        show={show}
        onHide={handleClose}
        className="showmenu--hamburgerbar--modal"
      >
        <div className="wrapper--menu--hamburger">
          <div className="wrapper--section--hamburger">
            <Row>
              <Col xs={6} className="menu--hamburger">
                <a href="https://game.lsmlondon789.asia/">
                  <div className="menu--hamburger--img">
                    <img src={menuPlayGame} />
                  </div>
                  <h5>เล่นเกมส์</h5>
                </a>
              </Col>
              <Col xs={6} className="menu--hamburger">
                <a href="https://game.lsmlondon789.asia/">
                  <div className="menu--hamburger--img">
                    <img src={menuCashBack} />
                  </div>
                  <h5>รับเงินคืน</h5>
                </a>
              </Col>
            </Row>
            <Row>
              <Col xs={6} className="menu--hamburger">
                <a href="https://game.lsmlondon789.asia/">
                  <div className="menu--hamburger--img">
                    <img src={menuHistory} />
                  </div>
                  <h5>ประวัติทำรายการ</h5>
                </a>
              </Col>
              <Col xs={6} className="menu--hamburger">
                <a href="/PromotionPage">
                  <div className="menu--hamburger--img">
                    <img src={menuPromotion} />
                  </div>
                  <h5>โปรโมชั่น</h5>
                </a>
              </Col>
            </Row>
            <Row>
              <Col xs={6} className="menu--hamburger">
                <a href="https://game.lsmlondon789.asia/">
                  <div className="menu--hamburger--img">
                    <img src={menuAffiliate} />
                  </div>
                  <h5>แนะนำเพื่อน</h5>
                </a>
              </Col>
              <Col xs={6} className="menu--hamburger">
                <a href="https://game.lsmlondon789.asia/">
                  <div className="menu--hamburger--img">
                    <img src={menuContact} />
                  </div>
                  <h5>ติดต่อเรา</h5>
                </a>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="menu--hamburger">
                <a
                  href="https://game.lsmlondon789.asia/"
                  className="menu--hamburger--btn"
                >
                  <h5> ติดตั้ง Application </h5>
                </a>
              </Col>
            </Row>
            {/* <Row>
                    <Col xs={12} className='menu--hamburger'>
                        <a href='https://game.lsmlondon789.asia/' className='menu--hamburger--btn'>
                            <h5> ออกจากระบบ </h5>
                        </a>
                    </Col>
                </Row> */}
          </div>
        </div>
      </Modal>

      {/* <Notificaion /> */}
      <Modal
        show={showNotification}
        onHide={handleNotificationClose}
        className="show--notification--modal"
      >
        <div className="wrapper--notification">
          <span className="notification--title">แจ้งเตือน</span>
          <span
            className="notification--close"
            onClick={handleNotificationClose}
          >
            X
          </span>
          <img src={headerBg} className="header-bg-nofi" />
          <Row className="notification--row">
            <Col xs={3} className="menu--notification">
              <a href="https://game.lsmlondon789.asia/">
                <div className="menu--notification--img">
                  <img src={nofiCateAll} />
                </div>
              </a>
              <p>ทั้งหมด</p>
            </Col>
            <Col xs={3} className="menu--notification">
              <a href="https://game.lsmlondon789.asia/">
                <div className="menu--notification--img">
                  <img src={nofiDeposit} />
                </div>
              </a>
              <p>ฝากเงิน</p>
            </Col>
            <Col xs={3} className="menu--notification">
              <a href="https://game.lsmlondon789.asia/">
                <div className="menu--notification--img">
                  <img src={nofiWithdraw} />
                </div>
              </a>
              <p>ถอนเงิน</p>
            </Col>
            <Col xs={3} className="menu--notification">
              <a href="https://game.lsmlondon789.asia/">
                <div className="menu--notification--img">
                  <img src={nofiBonus} />
                </div>
              </a>
              <p>โบนัส</p>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
}
