import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
// import OpenMenuHamburger from '../Pages/OpenMenuHamburger';
// import Notificaion from '../Pages/Notificaion';

import { Container, Row, Col, Tab, Tabs } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
// import '../Style/OpenMenuHamburger.css';
export default function Header() {
  const menuPlayGame =
    "https://fs.cdnxn.com/landingLondon/Images/menu-play-game.png";
  const menuCashBack =
    "https://fs.cdnxn.com/landingLondon/Images/menu-cash-back.png";
  const menuHistory =
    "https://fs.cdnxn.com/landingLondon/Images/menu-history.png";
  const menuPromotion =
    "https://fs.cdnxn.com/landingLondon/Images/menu-promotion.png";
  const menuAffiliate =
    "https://fs.cdnxn.com/landingLondon/Images/menu-affiliate.png";
  const menuContact =
    "https://fs.cdnxn.com/landingLondon/Images/menu-contact.png";

  const headerBg = "https://fs.cdnxn.com/landingLondon/Images/header-bg.png";
  const headerPlaygame =
    "https://fs.cdnxn.com/landingLondon/Images/play-games.png";
  const headerStamp =
    "https://fs.cdnxn.com/landingLondon/Images/header-stamp.png";
  const headerLogoBlack =
    "https://fs.cdnxn.com/landingLondon/Images/logo-black.png";
  const headerUsernameMoney =
    "https://fs.cdnxn.com/landingLondon/Images/icon-money.png";
  const headerBtnLogin =
    "https://fs.cdnxn.com/landingLondon/Images/btn-header-login.png";
  const headerBtnRegister =
    "https://fs.cdnxn.com/landingLondon/Images/btn-header-register.png";
  const headerBtnLine =
    "https://fs.cdnxn.com/landingLondon/Images/btn-line.png";
  const headerBgBtnLine =
    "https://fs.cdnxn.com/landingLondon/Images/btn-bg-white.png";
  const headerBtnNofi = "https://fs.cdnxn.com/landingLondon/Images/nofi.png";
  const headerBtnBurgerBar =
    "https://fs.cdnxn.com/landingLondon/Images/burgerbar.png";

  const headerBgMb =
    "https://fs.cdnxn.com/landingLondon/Images/header-bg-mobile.png";

  const nofiCateAll =
    "https://fs.cdnxn.com/landingLondon/Images/nofi-category-all.png";
  const nofiDeposit =
    "https://fs.cdnxn.com/landingLondon/Images/nofi-deposit.png";
  const nofiWithdraw =
    "https://fs.cdnxn.com/landingLondon/Images/nofi-withdraw.png";
  const nofiBonus =
    "https://fs.cdnxn.com/landingLondon/Images/nofi-cash-back.png";

  const mbHeaderLine =
    "https://fs.cdnxn.com/landingLondon/Images/mb-header-line.png";

  const [show, setShow] = useState(false);
  const [showNotification, setShowNotification] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleNotificationClose = () => setShowNotification(false);
  const handleNotificationShow = () => setShowNotification(true);

  return (
    <>
      {/* <OpenMenuHamburger /> */}
      <div className="header">
        <div className="section--img">
          <div className="pc">
            <div className="section--item">
              <div className="section--logo">
                <div className="logo-as">
                  <a href="/">
                    <img src={headerLogoBlack} className="img--logo" />
                  </a>
                </div>

                <div className="section--username">
                  {/* <h5>Mr. Climaximo</h5>
                    <div className='username--money'>
                      <img src={headerUsernameMoney} className='icon--money' />
                      <p>5,000,000.00</p>
                    </div> */}
                  <div className="btn-register">
                    <a href="https://game.lsmlondon789.asia//register">
                      <img src={headerBtnRegister} />
                    </a>
                  </div>
                  <div className="btn-login">
                    <a href="https://game.lsmlondon789.asia/">
                      <img src={headerBtnLogin} />
                    </a>
                  </div>
                </div>
              </div>

              <div className="header-play-games-section-top">
                <a
                  href="https://game.lsmlondon789.asia/"
                  className="go-to-homepage"
                ></a>
                <a href="/PromotionPage" className="go-to-promotion"></a>
                <img src={headerPlaygame} className="header-play-games" />
              </div>

              <div className="header--right">
                {/* <a href='https://game.lsmlondon789.asia/'> */}
                <div className="line">
                  <a href="https://line.me/R/ti/p/@559tjxps" target="_blank">
                    <img src={headerBtnLine} className="imgHeaderLines" />{" "}
                    <p>เป็นเพื่อนกับเรา</p>
                    <img src={headerBgBtnLine} className="imgHeaderBgBtnLine" />
                  </a>
                </div>
                {/* </a> */}
                <a href="#">
                  <img
                    src={headerBtnNofi}
                    className="btn--notify"
                    onClick={handleNotificationShow}
                  />
                </a>
                <a href="#">
                  <img
                    src={headerBtnBurgerBar}
                    className="btn--burgerbar"
                    onClick={handleShow}
                  />
                </a>
              </div>
            </div>
            <img src={headerBg} className="header-bg" />
            <img src={headerStamp} className="header-stamp" />
          </div>

          <div className="mobile">
            <img src={headerBgMb} className="mb--header-bg" />

            <div className="mb--section">
              <div className="mb--login">
                {/* <div className='btn-login'>
                <a href='https://game.lsmlondon789.asia/'><img src={headerBtnLogin} /></a>
              </div> */}

                <div className="line">
                  {/* <a href='https://line.me/R/ti/p/@559tjxps' target="_blank">
                        <img src={headerBtnLine} className="imgHeaderLines" /> <p>เป็นเพื่อนกับเรา</p>
                    </a>
                        <img src={headerBgBtnLine} className="imgHeaderBgBtnLine" /> */}
                  {/* <a href='https://line.me/R/ti/p/@559tjxps' target="_blank">
                                        <img src={mbHeaderLine} alt="contact line" className="imgHeaderBgBtnLine" />
                                    </a> */}
                </div>
              </div>
              <div className="logo-as">
                <div className="mb--logo">
                  <img src={headerLogoBlack} className="img--logo mobile" />
                </div>
              </div>

              <div className="mb--right">
                <a href="#">
                  <img
                    src={headerBtnNofi}
                    className="btn--notify"
                    onClick={handleNotificationShow}
                  />
                </a>
                <a href="#">
                  <img
                    src={headerBtnBurgerBar}
                    className="btn--burgerbar"
                    onClick={handleShow}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <OpenMenuHamburger/> */}
      <Modal
        show={show}
        onHide={handleClose}
        className="showmenu--hamburgerbar--modal"
      >
        <div className="wrapper--menu--hamburger">
          <div className="wrapper--section--hamburger">
            <Row>
              <Col xs={6} className="menu--hamburger">
                <a href="https://game.lsmlondon789.asia/">
                  <div className="menu--hamburger--img">
                    <img src={menuPlayGame} />
                  </div>
                  <h5>เล่นเกมส์</h5>
                </a>
              </Col>
              <Col xs={6} className="menu--hamburger">
                <a href="https://game.lsmlondon789.asia/">
                  <div className="menu--hamburger--img">
                    <img src={menuCashBack} />
                  </div>
                  <h5>รับเงินคืน</h5>
                </a>
              </Col>
            </Row>
            <Row>
              <Col xs={6} className="menu--hamburger">
                <a href="https://game.lsmlondon789.asia/">
                  <div className="menu--hamburger--img">
                    <img src={menuHistory} />
                  </div>
                  <h5>ประวัติทำรายการ</h5>
                </a>
              </Col>
              <Col xs={6} className="menu--hamburger">
                <a href="/PromotionPage">
                  <div className="menu--hamburger--img">
                    <img src={menuPromotion} />
                  </div>
                  <h5>โปรโมชั่น</h5>
                </a>
              </Col>
            </Row>
            <Row>
              <Col xs={6} className="menu--hamburger">
                <a href="https://game.lsmlondon789.asia/">
                  <div className="menu--hamburger--img">
                    <img src={menuAffiliate} />
                  </div>
                  <h5>แนะนำเพื่อน</h5>
                </a>
              </Col>
              <Col xs={6} className="menu--hamburger">
                <a href="https://game.lsmlondon789.asia/">
                  <div className="menu--hamburger--img">
                    <img src={menuContact} />
                  </div>
                  <h5>ติดต่อเรา</h5>
                </a>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="menu--hamburger">
                <a
                  href="https://game.lsmlondon789.asia/"
                  className="menu--hamburger--btn"
                >
                  <h5> ติดตั้ง Application </h5>
                </a>
              </Col>
            </Row>
            {/* <Row>
                    <Col xs={12} className='menu--hamburger'>
                        <a href='https://game.lsmlondon789.asia/' className='menu--hamburger--btn'>
                            <h5> ออกจากระบบ </h5>
                        </a>
                    </Col>
                </Row> */}
          </div>
        </div>
      </Modal>

      {/* <Notificaion /> */}
      <Modal
        show={showNotification}
        onHide={handleNotificationClose}
        className="show--notification--modal"
      >
        <div className="wrapper--notification">
          <span className="notification--title">แจ้งเตือน</span>
          <span
            className="notification--close"
            onClick={handleNotificationClose}
          >
            X
          </span>
          <img src={headerBg} className="header-bg-nofi" />
          <Row className="notification--row">
            <Col xs={3} className="menu--notification">
              <a href="https://game.lsmlondon789.asia/">
                <div className="menu--notification--img">
                  <img src={nofiCateAll} />
                </div>
              </a>
              <p>ทั้งหมด</p>
            </Col>
            <Col xs={3} className="menu--notification">
              <a href="https://game.lsmlondon789.asia/">
                <div className="menu--notification--img">
                  <img src={nofiDeposit} />
                </div>
              </a>
              <p>ฝากเงิน</p>
            </Col>
            <Col xs={3} className="menu--notification">
              <a href="https://game.lsmlondon789.asia/">
                <div className="menu--notification--img">
                  <img src={nofiWithdraw} />
                </div>
              </a>
              <p>ถอนเงิน</p>
            </Col>
            <Col xs={3} className="menu--notification">
              <a href="https://game.lsmlondon789.asia/">
                <div className="menu--notification--img">
                  <img src={nofiBonus} />
                </div>
              </a>
              <p>โบนัส</p>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
}
