import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from '../Pages/Home'
import PromotionPage from '../Pages/PromotionPage'
import Casino from '../Pages/Casino'
import Slot from '../Pages/Slot'
import Sport from '../Pages/Sport'
import Lotto from '../Pages/Lotto'

export default function RoutePages() {
  return (
    <Routes>
      <Route path="/" element={<Home />}></Route>
      <Route path="/PromotionPage" element={<PromotionPage />}></Route>
      <Route path="/casino" element={<Casino />}></Route>
      <Route path="/slot" element={<Slot />}></Route>
      <Route path="/sport" element={<Sport />}></Route>
      <Route path="/lotto" element={<Lotto />}></Route>
    </Routes>
  )
}
